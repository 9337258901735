export class User {
  name: string
  email: string

  constructor(options: Partial<User> = {}) {
    if (options) {
      // @ts-ignore
      Object.keys(options).forEach((key) => options[key] === undefined && delete options[key])
      Object.assign(this, options)
    }
  }
}
