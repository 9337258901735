export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const checkIfKeyMatches = (key: string, key2: string) => {
  if (key?.toLowerCase() === key2?.toLowerCase()) {
    return true
  }
  return key?.toLowerCase().startsWith('custom') && key2?.toLowerCase().startsWith('custom')
}
