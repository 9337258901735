import type { FirebaseApp } from 'firebase/app'
import type { Database } from 'firebase/database'
import type { Auth, GoogleAuthProvider as AuthGoogleAuthProvider } from 'firebase/auth'

const FIREBASE_CREDENTIALS = {
  apiKey: 'AIzaSyD39O-K-En2fsT8mTTIgUHF689zVeN-65c',
  authDomain: 'dd-orders-db.firebaseapp.com',
  projectId: 'dd-orders-db',
  databaseURL: 'https://dd-orders-db-default-rtdb.firebaseio.com/',
}

export const FIREBASE_CONFIG = () => {
  return FIREBASE_CREDENTIALS
}

let app: FirebaseApp | undefined
let database: Database | undefined
let appAuth: Auth | undefined
let googleAuthProvider: AuthGoogleAuthProvider | undefined

const initializeFirebase = async () => {
  const { initializeApp } = await import('firebase/app')
  const { getDatabase } = await import('firebase/database')
  const { getAuth, GoogleAuthProvider } = await import('firebase/auth')

  const firebaseConfig = FIREBASE_CONFIG()
  app = initializeApp(firebaseConfig)
  database = getDatabase(app)
  appAuth = getAuth(app)
  googleAuthProvider = new GoogleAuthProvider()
}

// Initialize Firebase if the feature flag is enabled
initializeFirebase().catch((err) => console.error('Error initializing Firebase:', err))

const getFirebaseServices = async () => {
  await initializeFirebase()
  return { app, database, appAuth, googleAuthProvider }
}

export { getFirebaseServices }
