export class AuthCreds {
  authorization: string
  customToken: string
  refreshToken: string

  constructor(options: Partial<AuthCreds>) {
    if (options) {
      // @ts-ignore
      Object.keys(options).forEach((key) => options[key] === undefined && delete options[key])
      Object.assign(this, options)
    }
  }
}
