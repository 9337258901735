import { OrdersDto } from '../dtos/orders.dto'

export class OrdersService {
  private static _instance: OrdersService
  private orders: OrdersDto[] = []

  public static getInstance(): OrdersService {
    return this._instance || (this._instance = new this())
  }

  public getOrders() {
    return this.orders
  }

  public setOrders(orders: OrdersDto[]) {
    orders.sort((a, b) => b.createdTime - a.createdTime)
    this.orders = orders
  }
}
