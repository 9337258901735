import React, { useContext, useState } from 'react'
import './generic-checkbox.css'
import { UserContext, UserContextType } from '../../../contexts/user.context'
import { DEFAULT_EMAIL } from '../../../utils/constants'

interface Props {
  checked: boolean
  onChange: (isChecked: boolean) => void
}

export const GenericCheckbox: React.FC<Props> = ({ checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked)
  const { user } = useContext<UserContextType>(UserContext)
  const handleCheckboxClick = () => {
    if (user.email !== DEFAULT_EMAIL) {
      return
    }
    setIsChecked(!isChecked)
    if (onChange) {
      onChange(!isChecked)
    }
  }
  return (
    <div className='generic-checkbox' onClick={handleCheckboxClick}>
      <div className={`generic-checkbox-box ${isChecked ? 'checked' : ''}`}>
        {isChecked && <div className='generic-checkbox-checkmark'>✔</div>}
      </div>
    </div>
  )
}
