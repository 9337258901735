export class CategoryDto {
  id: string
  name: string
  sortOrder: number
  color: string

  constructor(options: Partial<CategoryDto>) {
    if (options) {
      // @ts-ignore
      Object.keys(options).forEach((key) => options[key] === undefined && delete options[key])
      Object.assign(this, options)
    }
  }
}
