import React from 'react'
import spinnerLoader from '../../assets/common/spinning-loader.png'
import './loading-component.css'

const LoadingComponent = () => {
  const componentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
  }

  return (
    <div style={componentStyle}>
      <img src={spinnerLoader} className={'spinner-loader'} draggable='false' alt={'loading..'} />
    </div>
  )
}

export default LoadingComponent
