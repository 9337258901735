import { OrdersService } from './orders.service'

export class ItemsCountService {
  // singleton instance

  private static instance: ItemsCountService
  private itemsCountMap: Map<string, { title: string, count: number }> = new Map()

  private constructor() {
    // private constructor
  }

  public static getInstance(): ItemsCountService {
    if (!ItemsCountService.instance) {
      ItemsCountService.instance = new ItemsCountService()
    }
    return ItemsCountService.instance
  }

  public setItemsCountMap(itemsCountMap: Map<string, { title: string, count: number }>) {
    this.itemsCountMap = itemsCountMap
  }

  public getItemsCountMap() {
    return this.itemsCountMap
  }

  public refreshItemCountMap() {
    const itemsCountMap = new Map<string, { title: string, count: number }>()
    OrdersService.getInstance()
      .getOrders()
      .forEach((order) => {
        order.items.forEach((item, key) => {
          if (!item.status) {
            const itemId = item.itemId ?? item.name
            item.itemId = itemId
            if (!itemsCountMap.has(itemId)) {
              itemsCountMap.set(itemId, {
                title: item.name,
                count: item.quantity,
              })
            } else {
              itemsCountMap.set(itemId, {
                title: item.name,
                count: (itemsCountMap.get(itemId)?.count ?? 0) + item.quantity,
              })
            }
          }
        })
      })
    this.setItemsCountMap(itemsCountMap)
  }
}
