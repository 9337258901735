import { API_RETRY_LIMIT, AXIOS_NETWORK_ERR_CODE, AXIOS_TIMEOUT_CODE } from '../utils/constants'

import axios, { AxiosInstance } from 'axios'
import { AuthCreds } from '../types/auth-creds'
import { LocalstorageService } from '../services/local-storage.service'
import { JwtPayload, jwtDecode } from 'jwt-decode'
import { User } from '../dtos/user.dto'

interface UserJwtPayload extends JwtPayload {
  email: string
  name: string
}

export default class AxiosService {
  private readonly baseUrl: string
  private axiosClient: AxiosInstance
  private authCreds: AuthCreds
  private retryCount: number = 0
  private headerObject: any = {}

  private constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_API_URL as string
    if (this.baseUrl && this.baseUrl.endsWith('/')) {
      this.baseUrl = this.baseUrl.substring(0, this.baseUrl.length - 1)
    }
    this.headerObject = {
      'Content-Type': 'application/json',
    }
    this.authCreds = LocalstorageService.getInstance().getAuthCred()
    this.axiosClient = axios.create({
      baseURL: `${this.baseUrl}/`,
      timeout: 10000, // 10 sec request timeout
      withCredentials: false, // to avoid CORS error
      headers: {
        ...this.headerObject,
        ...this.authCreds,
        customToken: undefined,
      },
    })
    this.initAxiosClient()
  }

  private static _instance: AxiosService

  public static getInstance(): AxiosService {
    return this._instance || (this._instance = new this())
  }

  public getAxiosClient() {
    return this.axiosClient
  }

  public resetAxiosClient() {
    this.authCreds = new AuthCreds({})
    this.axiosClient = axios.create({
      baseURL: `${this.baseUrl}/`,
      timeout: 10000, // 10 sec request timeout
      headers: {},
      withCredentials: false,
    })
    this.initAxiosClient()
  }

  public getUserFromToken() {
    const token = this.authCreds.authorization.replace(
      'Bearer', '',
    ).trim()
    try {
      const decoded: UserJwtPayload = jwtDecode<UserJwtPayload>(token)
      return new User({
        email: decoded.email,
        name: decoded.name,
      })
    } catch (error) {
      console.error('Invalid JWT token:', error)
      return new User({
        email: '',
        name: '',
      })
    }
  }

  public setCreds(authorization: string, customToken: string, refreshToken: string) {
    this.authCreds = new AuthCreds({ authorization, customToken, refreshToken })
    LocalstorageService.getInstance().setAuthCred(this.authCreds)
    this.axiosClient = axios.create({
      baseURL: `${this.baseUrl}/`,
      timeout: 10000, // 10 sec request timeout
      headers: {
        ...this.headerObject,
        Authorization: authorization,
        refreshToken,
        customToken: undefined,
      },
    })
    this.initAxiosClient()
  }

  public refreshCreds() {
    this.axiosClient = axios.create({
      baseURL: `${this.baseUrl}/`,
      timeout: 10000, // 10 sec request timeout
      headers: {
        ...this.headerObject,
        ...this.authCreds,
        customToken: undefined,
      },
    })
    this.initAxiosClient()
  }

  public validateToken() {
    return !!this.authCreds.authorization
  }

  public getCreds() {
    return this.authCreds
  }

  private initAxiosClient() {
    this.axiosClient.interceptors.response.use(
      (response) => {
        response.headers['x-new-token'] &&
        (this.authCreds.authorization = response.headers['x-new-token'])
        LocalstorageService.getInstance().setAuthCred(this.authCreds)
        this.refreshCreds()
        this.retryCount = 0
        return response
      },
      async (err) => {
        if (
          err.code === AXIOS_TIMEOUT_CODE ||
          err.code === AXIOS_NETWORK_ERR_CODE ||
          err.code === 429 ||
          err.response?.status >= 500
        ) {
          this.retryCount += 1
          if (API_RETRY_LIMIT < this.retryCount) {
            throw err
          }
          const request = { ...err.config }
          console.error(`API RETRY:${this.retryCount}:${request.url}:${JSON.stringify(err)}`)
          return this.retryRequest(request)
        }
        this.retryCount = 0
        if (err.response?.status === 401) {
          // TODO: redirect to login page
          console.error('Unauthorized request')
        }
        return err
      },
    )
  }

  private retryRequest(request: any) {
    request.headers = {
      ...request.headers,
      Authorization: this.authCreds.authorization,
    }
    return this.axiosClient.request(request)
  }
}
