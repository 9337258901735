export class OrderItemsDto {
  ids: string[]
  itemId: string
  name: string
  quantity: number
  price: number
  status: boolean
  key: string

  constructor(options: Partial<OrderItemsDto>) {
    if (options) {
      // @ts-ignore
      Object.keys(options).forEach((key) => options[key] === undefined && delete options[key])
      Object.assign(this, options)
    }
  }
}

export class OrdersDto {
  id: string
  title: string
  state: 'locked' | 'open'
  items: Map<string, OrderItemsDto>
  createdTime: number

  constructor(options: Partial<OrdersDto>) {
    if (options) {
      // @ts-ignore
      Object.keys(options).forEach((key) => options[key] === undefined && delete options[key])
      Object.assign(this, options)
    }
    if (options.items) {
      this.items = new Map<string, OrderItemsDto>()
      const entries = Object.entries(options.items)
      for (const [key, value] of entries) {
        const item = new OrderItemsDto(value)
        item.itemId = item.itemId ?? key
        this.items.set(item.itemId, new OrderItemsDto(value))
      }
    }
  }
}
