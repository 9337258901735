import React, { useCallback, useContext, useEffect } from 'react'
import { FirebaseService } from '../../services/firebase.service'
import { OrdersService } from '../../services/orders.service'
import { FirebaseEvents } from '../../services/firebase-events'
import './home.css'
import logoutBtn from '../../assets/logout_icon.svg'
import refreshBtn from '../../assets/refresh_icon.svg'
import OrderCard from './components/order-card.component'
import { ItemsCountService } from '../../services/items-count.service'
import { ItemsCountContainer } from './components/items-count.component'
import { useNavigate } from 'react-router-dom'
import { Navigate } from '../../utils/routes'
import AxiosService from '../../api/axios-client'
import { LocalstorageService } from '../../services/local-storage.service'
import { debouncedRefreshData, getCategories } from '../../api/auth.api'
import { toast } from 'react-toastify'
import { CategoryDto } from '../../dtos/category.dto'
import { UserContext, UserContextType } from '../../contexts/user.context'
import { DEFAULT_EMAIL } from '../../utils/constants'

export const HomeScreen = () => {
  const navigate = useNavigate()

  const [categoriesMap, setCategoriesMap] = React.useState<Map<string, CategoryDto>>(new Map())
  const [itemMap, setItemMap] = React.useState<Map<string, string>>(new Map())
  const { user, setUser } = useContext<UserContextType>(UserContext)
  const [isFirebaseInitialized, setIsFirebaseInitialized] = React.useState(false)
  const [orders, setOrders] = React.useState(OrdersService.getInstance().getOrders())
  const [itemsCountMap, setItemsCountMap] = React.useState<Map<string, { title: string, count: number }>>(
    ItemsCountService.getInstance().getItemsCountMap(),
  )
  const [isRefreshing, setIsRefreshing] = React.useState(false)
  const [itemFilter, setItemFilter] = React.useState('')

  const initializeFirebase = async () => {
    await FirebaseService.getInstance().initializeFirebase()
    FirebaseService.getInstance().on(FirebaseEvents.ORDERS_UPDATED, () => {
      setOrders([...OrdersService.getInstance().getOrders()])
    })
    await FirebaseService.getInstance().readOrdersData()
    FirebaseService.getInstance().addListeners()
    setIsFirebaseInitialized(true)
  }

  const logout = () => {
    AxiosService.getInstance().resetAxiosClient()
    LocalstorageService.getInstance().reset()
    FirebaseService.getInstance().reset()
    navigate(Navigate.AuthScreen)
  }

  useEffect(() => {
    if (!AxiosService.getInstance().validateToken()) {
      navigate(Navigate.AuthScreen)
    }
    if (isFirebaseInitialized) return
    initializeFirebase().then()
  }, [])

  useEffect(() => {
    ItemsCountService.getInstance().refreshItemCountMap()
    setItemsCountMap(ItemsCountService.getInstance().getItemsCountMap())
  }, [orders])

  useEffect(() => {
    getCategories().then((categories) => {
      const itemMap = new Map<string, string>()
      const categoriesMap = new Map<string, CategoryDto>()
      categories.forEach((category) => {
        categoriesMap.set(category.id, new CategoryDto({
          id: category.id,
          name: category.name,
          sortOrder: category.sortOrder,
          color: category.color,
        }))
        category.items.forEach((itemId) => {
          itemMap.set(itemId, category.id)
        })
      })
      setItemMap(itemMap)
      setCategoriesMap(categoriesMap)
    })
  }, [])

  useEffect(() => {
    setUser(AxiosService.getInstance().getUserFromToken())
  }, [])

  const refreshOrders = useCallback(() => {
    setIsRefreshing(true)
    debouncedRefreshData((isRefreshed: boolean) => {
      setIsRefreshing(false)
      if (isRefreshed) {
        toast.success('Orders Refreshed')
        ItemsCountService.getInstance().refreshItemCountMap()
      } else {
        toast.error('Failed to refresh orders')
      }
    })
  }, [])

  return (
    <div className={'home-main-container'}>
      <div className={'top-bar'}>
        Orders Tracking
        <div className={'icon-holder'}>
          <div
            className={`icon ${isRefreshing ? 'spin' : ''}`.trim()}
            onClick={refreshOrders}
          >
            <img src={refreshBtn} alt={'refresh'} />
          </div>
          <div className={'icon'} onClick={logout}>
            <img src={logoutBtn} alt={'logout'} />
          </div>
        </div>
      </div>
      <div className={'home-container'}>
        {user.email === DEFAULT_EMAIL &&
          <div className={'orders-container'}>
            {orders
              .filter((order) => {
                return Array.from(order.items.values()).some((item) => {
                  if (itemFilter === '') return true
                  return item.name.toLowerCase().includes(itemFilter.toLowerCase())
                })
              })
              .map((order) => {
                return <OrderCard order={order} key={order.id} selectedItemFilter={itemFilter} />
              })}
          </div>}
        <ItemsCountContainer
          itemsCountMap={itemsCountMap}
          itemFilter={itemFilter}
          setItemFilter={setItemFilter}
          categoriesMap={categoriesMap}
          itemMap={itemMap}
          fullscreen={user.email !== DEFAULT_EMAIL}
        />
      </div>
    </div>
  )
}
