import { User } from '../dtos/user.dto'
import { AuthCreds } from '../types/auth-creds'
import { USER_CREDS, USER_DETAILS } from '../utils/constants'

export class LocalstorageService {
  private static _instance: LocalstorageService

  public static getInstance(): LocalstorageService {
    return this._instance || (this._instance = new this())
  }

  public setUserDetails(user: User): void {
    return localStorage.setItem(USER_DETAILS, JSON.stringify(user))
  }

  public getUserDetails(): User {
    return new User(JSON.parse(localStorage.getItem(USER_DETAILS) ?? '{}'))
  }

  public removeUser(): void {
    localStorage.removeItem(USER_DETAILS)
  }

  public getAuthCred(): AuthCreds {
    return new AuthCreds(JSON.parse(localStorage.getItem(USER_CREDS) ?? '{}'))
  }

  public setAuthCred(authCred: AuthCreds): void {
    return localStorage.setItem(USER_CREDS, JSON.stringify(authCred))
  }

  public removeAuthCred() {
    localStorage.removeItem(USER_CREDS)
  }

  public reset() {
    localStorage.clear()
  }
}
