import React, { useEffect } from 'react'
import './items-count.css'
import { checkIfKeyMatches } from '../../../utils/common-utils'
import { CategoryDto } from '../../../dtos/category.dto'

interface Props {
  itemsCountMap: Map<string, { title: string, count: number }>
  setItemFilter: (value: string) => void
  itemFilter: string
  itemMap: Map<string, string>
  categoriesMap: Map<string, CategoryDto>
  fullscreen: boolean
}

export const ItemsCountContainer: React.FC<Props> = (
  {
    itemsCountMap,
    itemFilter,
    setItemFilter,
    categoriesMap,
    itemMap,
    fullscreen,
  }) => {
  const [categoryItemsMap, setCategoryItemsMap] = React.useState<Map<string, string[]>>(new Map())

  useEffect(() => {
    const categoryItemsMap = new Map<string, string[]>()
    Array.from(itemsCountMap.keys()).forEach((itemId: string) => {
      const category = categoriesMap.get(itemMap.get(itemId) ?? '')
      let categoryId = 'default'
      if (category) {
        categoryId = category.id
      }
      if (!categoryItemsMap.has(categoryId)) {
        categoryItemsMap.set(categoryId, [])
      }
      categoryItemsMap.get(categoryId)?.push(itemId)
      setCategoryItemsMap(categoryItemsMap)
    })
  }, [itemsCountMap, categoriesMap])
  return (
    <div className={`items-count-container ${fullscreen ? 'full' : ''}`}>
      {
        itemsCountMap.size > 0 &&
        Array.from(categoryItemsMap.keys())
          .sort(
            (a: string, b: string) => {
              return (categoriesMap.get(a)?.sortOrder ?? 0) - (categoriesMap.get(b)?.sortOrder ?? 0)
            },
          )
          .map((categoryId: string) => {
            const itemIds = categoryItemsMap.get(categoryId) ?? []
            if (itemIds.length === 0) {
              return null
            }
            return (
              <div className={'category'} key={categoryId} style={{
                background: categoriesMap.get(categoryId)?.color,
              }}>
                <div className={'title'}>{categoriesMap.get(categoryId)?.name ?? 'Others'}</div>
                {itemIds.map((itemId: string, index: number) => {
                  return (<div
                    key={itemId ?? `itemId--${index}`}
                    className={`items-count-item ${checkIfKeyMatches(itemFilter, itemId) ? 'active' : ''}`}
                    onClick={() => {
                      if (
                        itemFilter.toLowerCase().startsWith('custom') &&
                        itemsCountMap.get(itemId)?.title?.toLowerCase().startsWith('custom')
                      ) {
                        setItemFilter('')
                        return
                      }
                      if (itemFilter === itemsCountMap.get(itemId)?.title) {
                        setItemFilter('')
                        return
                      }
                      if (itemId?.toLowerCase().startsWith('custom')) {
                        setItemFilter('Custom Item')
                        return
                      }
                      setItemFilter(itemsCountMap.get(itemId)?.title ?? '')
                    }}
                  >
                    <div
                      className={`items-count-item-key ${checkIfKeyMatches(itemFilter, itemId) ? 'active' : ''}`}
                    >
                      {itemsCountMap.get(itemId)?.title}
                      {/*{categoriesMap.get(itemMap.get(key) ?? '')?.name}*/}
                    </div>
                    <div className="items-count-item-value">{itemsCountMap.get(itemId)?.count}</div>
                  </div>)
                })}
              </div>
            )
          })}
      {itemFilter !== '' ? (
        <div
          className={'clear-filter-container'}
          onClick={() => {
            setItemFilter('')
          }}
        >
          <div className={'clear-filter'}>Clear Filter</div>
        </div>
      ) : null}
    </div>
  )
}
