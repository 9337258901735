import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import './auth-screen.css'
import { signInWithCustomToken } from 'firebase/auth'
import { login, validateTokenApi } from '../../api/auth.api'
import { useNavigate } from 'react-router-dom'
import { Navigate } from '../../utils/routes'
import AxiosService from '../../api/axios-client'
import { getFirebaseServices } from '../../utils/firebase-config'
import { toast } from 'react-toastify'
import { UserContext, UserContextType } from '../../contexts/user.context'
import { User } from '../../dtos/user.dto'

const AuthScreen: React.FC = () => {
  const { setUser } = useContext<UserContextType>(UserContext)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    const validateToken = async () => {
      if (AxiosService.getInstance().validateToken() && (await validateTokenApi())) {
        await firebaseCustomAuth(AxiosService.getInstance().getCreds().customToken)
        navigate(Navigate.HomeScreen)
      } else {
        AxiosService.getInstance().resetAxiosClient()
        setInitialLoading(false)
      }
    }
    validateToken().then()
  }, [])

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    login(email, password)
      .then(async (loginResponse) => {
        if (!loginResponse) {
          return
        }
        const { success, response } = loginResponse
        if (success) {
          const {
            data: { token, idToken, refreshToken },
          } = response
          AxiosService.getInstance().setCreds(`Bearer ${idToken}`, token, refreshToken)
          await firebaseCustomAuth(token)
          setUser(new User({ email }))
          navigate(Navigate.HomeScreen)
        } else {
          toast('Invalid credentials')
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const firebaseCustomAuth = async (customToken: string) => {
    const { appAuth } = await getFirebaseServices()
    if (!appAuth) return
    try {
      await signInWithCustomToken(appAuth, customToken)
    } catch (e) {
      console.log(e)
    }
  }

  if (initialLoading) {
    return (
      <div className="login-container">
        <div className="spinner"></div>
      </div>
    )
  }

  return (
    <>
      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="login-title">Login</h2>
          <div className="login-inputGroup">
            <label htmlFor="email" className="login-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="login-input"
              required
            />
          </div>
          <div className="login-inputGroup">
            <label htmlFor="password" className="login-label">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="login-input"
              required
            />
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? <div className="spinner"></div> : 'Login'}
          </button>
        </form>
      </div>
    </>
  )
}

export default AuthScreen
