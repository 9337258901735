import AxiosService from './axios-client'
import { CATEGORIES_API, LOGIN_API, REFRESH_DATA_API, REFRESH_ORDER_BY_ID_API, VALIDATE_TOKEN_API } from './api-urls'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import debounce from 'lodash/debounce'
import { CategoryResponseDto } from '../dtos/categories-response.dto'

export type AuthResponseType = {
  success: boolean
  response: any
}

export const login = async (email: string, password: string): Promise<AuthResponseType> => {
  try {
    AxiosService.getInstance().resetAxiosClient()
    const response = await AxiosService.getInstance()
      .getAxiosClient()
      .post(`${LOGIN_API}?time=${new Date().getTime()}`, {
        email,
        password,
      })

    if (response.status >= 200 && response.status < 300) {
      toast(response.data)
      return { success: true, response }
    } else {
      toast(response.data)
      return { success: false, response }
    }
  } catch (error) {
    const e = error as AxiosError
    if (e.response) {
      // @ts-ignore
      toast(e.response.data)
    } else {
      // @ts-ignore
      toast(error.toString())
    }
    return { success: false, response: error }
  }
}

export const refreshData = async (): Promise<boolean> => {
  try {
    await AxiosService.getInstance().getAxiosClient().get(REFRESH_DATA_API)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const validateTokenApi = async (): Promise<boolean> => {
  try {
    await AxiosService.getInstance().getAxiosClient().get(VALIDATE_TOKEN_API)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const debouncedRefreshData = debounce((callback) => {
  refreshData().then((value) => {
    callback(value)
  })
}, 500)

export const refreshOrderByID = async (id: string): Promise<void> => {
  const url = `${REFRESH_ORDER_BY_ID_API}/${id}`
  try {
    await AxiosService.getInstance().getAxiosClient().get(url)
  } catch (error) {
    console.log(error)
  }
}

export const getCategories = async (): Promise<CategoryResponseDto[]> => {
  try {
    const response = await AxiosService.getInstance().getAxiosClient().get(CATEGORIES_API)
    return await response.data
  } catch (e) {
    console.error(e)
    return []
  }
}
// Create a Map to store debounced functions
const debouncedFunctions = new Map<string, Function>()

// Function to get a debounced version of refreshOrderByID for a specific ID
export const getDebouncedRefreshOrderByID = (id: string) => {
  if (!debouncedFunctions.has(id)) {
    const debouncedFunction = debounce(async () => {
      await refreshOrderByID(id)
    }, 1000)
    debouncedFunctions.set(id, debouncedFunction)
  }
  return debouncedFunctions.get(id)
}

// Example usage
export const callDebouncedRefreshOrderByID = (id: string) => {
  const debouncedFunction = getDebouncedRefreshOrderByID(id)
  debouncedFunction && debouncedFunction()
}
