import { OrderItemsDto, OrdersDto } from '../../../dtos/orders.dto'
import React, { useContext } from 'react'
import './order-card.css'
import { FirebaseService } from '../../../services/firebase.service'
import { GenericCheckbox } from '../../../components/common/checkbox/generic-checkbox.component'
import { callDebouncedRefreshOrderByID } from '../../../api/auth.api'
import { checkIfKeyMatches } from '../../../utils/common-utils'
import { UserContext, UserContextType } from '../../../contexts/user.context'
import { DEFAULT_EMAIL } from '../../../utils/constants'

interface Props {
  order: OrdersDto
  selectedItemFilter: string
}

const OrderCard: React.FC<Props> = ({ order, selectedItemFilter }) => {
  // console.log('order', order)
  const { user } = useContext<UserContextType>(UserContext)

  const handleOrderItemStatusChange = (
    key: string,
    item: OrderItemsDto,
    orderId: string,
    isChecked: boolean,
  ) => {
    if (user.email !== DEFAULT_EMAIL) {
      return
    }
    let itemKey = item.name.toUpperCase()
    if (itemKey.startsWith('CUSTOM')) {
      itemKey = item.key
    }

    FirebaseService.getInstance()
      .updateOrderItemStatus(orderId, itemKey, isChecked, item)
      .then(async () => {
        console.log('status updated')
        callDebouncedRefreshOrderByID(orderId)
      })
  }

  const keys = [...order.items.keys()]

  // add items in capital letters that are not required

  // const notRequiredItems = []

  // console.log(order)

  return (
    <div className={`order-card ${order.state}`}>
      <div className={'title'}>{order.title}</div>
      <div className={'items'}>
        <div className={'item header'}>
          <div className={'cell'}>Name</div>
          <div className={'cell'}>Quantity</div>
          <div className={'cell'}>Status</div>
        </div>
        {keys.map((key) => {
          const item = order.items.get(key)
          if (!item) {
            return null
          }
          // if (notRequiredItems.indexOf((item.name.toUpperCase())) > -1) {
          //   return null
          // }
          return (
            <div
              key={`${item.ids.join(',')}-${item.status}`}
              className={`item ${checkIfKeyMatches(item.name, selectedItemFilter) && !item.status ? 'active' : ''}`}
            >
              <div className={'cell'}>{item.name}</div>
              <div className={'cell'}>{item.quantity}</div>
              <div className={'cell'} onClick={() => {
              }}>
                <GenericCheckbox
                  checked={item.status}
                  onChange={(isChecked) => {
                    handleOrderItemStatusChange(key, item, order.id, isChecked)
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(OrderCard)
