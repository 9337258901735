import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React, { Suspense } from 'react'
import { Navigate } from './utils/routes'
import { HomeScreen } from './screens/home/home.screen'
import LoadingComponent from './components/loading/loading.component'
import AuthScreen from './screens/auth/auth.screen'
import { UserContext } from './contexts/user.context'
import { User } from './dtos/user.dto'

const AppWrapperComponent = () => {
  const [user, setUser] = React.useState<User>(new User({}))
  return (
    <UserContext.Provider value={{ setUser, user }}>
      <div className="App" id={'main-app-div'}>
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <Routes>
              <Route path={Navigate.AuthScreen} element={<AuthScreen />} />
              <Route path={Navigate.HomeScreen} element={<HomeScreen />}></Route>
              <Route path="*" element={<AuthScreen />} />
            </Routes>
          </Suspense>
        </Router>
      </div>
    </UserContext.Provider>
  )
}

export default AppWrapperComponent
